export const namespaced = true;

const getDefaultState = () => {
    return {
        claim_form: {
            fixed_client: null,
            repair_type: null,

            re_claim_guid: null,

            customer_request_type: null, //for pledge repair only

            maker: null,
            device_model: null,

            // device_model:
            // {
            //     id: null,
            //     data: null,
            //     producent: null,
            //     model: null,
            //     model_serwis: null,
            //     model_handel: null,
            //     imei_sn: null,
            //     courier_type: null
            // },

            device_faults: [],
            device_faults_other: null,

            included_items: [],
            included_items_other: null,

            device_visual_conditions: [],
            device_visual_conditions_other: null,

            attachment_ids: [],
            attachments: [],

            sender: {},
            show_recipient: false,
            recipient: {},
            show_payer: false,
            payer: {},

            transport_in: null,
            transport_out: null,
            transport_inpost_paczkomat: {
                name: null,
                address_details: null,
                location_description: null,
                opening_hours: null,
                payment_available: null,
            },

            transport_dhl_parcel_shop: {
                id: null,
                sap: null,
                name: null,
                zip: null,
                street: null,
                city: null,
                streetNo: null,
                houseNo: null
            },

            transport_courier: {
                transport_day: null,
                transport_hours: null,
                client_print_label: false,
            },

            regulations_agree: false,
            regulations_important_note: false,
            xiaomi_privacy_policy: false,
            samsung_processing_personal_data: false,
            regulations_rodo1: false,
            regulations_rodo2: false,
            regulations_select_all: false,

            expertise_agreement: false,
            anticipatory_expertise_request: false,
            anticipatory_expertise_request_confirmed: false,
            commercial_information_consent: false,
            regulations_rsude: false,
            regulations_privacy_policy: false,
            regulations_iopdo: false,
            regulatons_pdoou: false,

            entrepreneur_statement: false,
        },
        loading: false,
        error: {
            sender_last_name: 'Brak'
        },
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_CLAIM_FORM(state, claim_form) {
        state.claim_form = claim_form;
    },
    UPDATE_CLAIM_FORM(state, payload) {
        state.claim_form[payload.key] = payload.value;
    },
    SYNC_CLAIM_FORM_FIELD_VALUES(state, payload) {
        if (state.claim_form[payload.key].includes(payload.value)) {
            state.claim_form[payload.key] = state.claim_form[
                payload.key
            ].filter(function (value) {
                if (value !== payload.value) return value;
            });
        } else {
            state.claim_form[payload.key].push(payload.value);
        }
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    },
};

export const actions = {
    setClaimForm({commit}) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", {});
        commit("SET_CLAIM_FORM", {});
        commit("SET_LOADING", false);
    },
    updateClaimForm({commit}, payload) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", {});
        commit("UPDATE_CLAIM_FORM", payload);
        commit("SET_LOADING", false);
    },
    syncValue({commit}, payload) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", {});
        commit("SYNC_CLAIM_FORM_FIELD_VALUES", payload);
        commit("SET_LOADING", false);
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
};

export const getters = {
    claim_form: (state) => {
        return state.claim_form;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
